import React, { Fragment, useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import { Row, Col, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Commissiondata } from "../../../services/api_function";
import { useSelector } from "react-redux";

const CommissionData = (props) => {

  const location = useLocation();
  const phoneNumberFromUrl = new URLSearchParams(location.search).get("user");
  const [user, setUser] = useState(phoneNumberFromUrl);
  const [userData, setUserData] = useState([]);
  const [apiData, setapiData] = useState([]);

  const isInitialRender = useRef(true);
  const User = useMemo(() => user, [user]);
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    Commissiondata(User, token)
      .then((response) => {
        setUserData(response?.data);
        setapiData(response?.regData);
      })
      .catch((error) => {
        console.error("Error fetching team data:", error);
      });
  }, [User]);

  console.log("UserData:", userData);
  const navigate = useNavigate();

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <i
                class="fas fa-circle-left "
                style={{ fontSize: "2rem" }}
                onClick={() => navigate(-1)}
              ></i>
              <Card.Title style={{ color: "white", margin: "auto" }}>
                Commission
              </Card.Title>
            </Card.Header>
            <Card.Body
              style={{ background: "black", border: "1px solid white" }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "1px solid white",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <strong>Today Reward</strong>
                    </th>
                    <th>
                      <strong>Total Reward</strong>
                    </th>
                    <th>
                      <strong>Trading Volume</strong>
                    </th>
                    <th>
                      <strong>Team Business</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: "middle" }}>
                      {(userData?.users?.wallet_recurr ?? 0).toFixed(2)}
                    </td>
                    <td>
                      {(
                        (userData[0]?.["56"]?.total_wbnb ?? 0) +
                        (userData[0]?.["56"]?.total_crv ?? 0) +
                        (userData[0]?.["137"]?.total_wmatic ?? 0) +
                        (userData[0]?.["137"]?.total_crv ?? 0)
                      ).toFixed(2)}
                    </td>

                    <td style={{ verticalAlign: "middle" }}>
                      {(apiData[0]?.gleamUserBusiness ?? 0).toFixed(2)}
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      {(apiData[0]?.gleamTeamBusiness ?? 0).toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CommissionData;
